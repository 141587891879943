import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs'
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	hcURL = environment.API_GENERAL_URL;
	constructor(private angularFireMessaging: AngularFireMessaging,public afAuth: AngularFireAuth,private firestore: AngularFirestore,public router: Router,private db: AngularFireDatabase) {
		this.afAuth.onIdTokenChanged(tokenChanged => {
			this.requestPermission();
		})
	}
	
	requestPermission() {
		return this.angularFireMessaging.requestToken;
	}
	
	async register(email: string, password: string) {
		try {
			const response = await this.afAuth.signInWithEmailAndPassword(email, password);
			return response;
		}
		catch (e:any) {
			var signData = await this.afAuth.createUserWithEmailAndPassword(email, password);
			return signData;
		}
	}
	
	sendTokenToServer(currentToken:any) {
		localStorage.setItem('deviceToken', currentToken!);
		if (!this.isTokenSentToServer()) {
			this.setTokenSentToServer(true);
		} else {
		}
	}
	
	isTokenSentToServer() {
		return window.localStorage.getItem('sentToServer') == 'true';
	}
	
	setTokenSentToServer(sent: boolean) {
		window.localStorage.setItem('sentToServer', sent ? 'true' : 'false');
	}
	
	addVideoCallEntry(docData:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(docData.apptId.toString())
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}

	updateToTriggerSnapshotChange(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({apptId:apptId.toString()})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	allowCandidateToJoin(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({candidateCanJoin:2})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	notallowCandidateToJoin(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({candidateCanJoin:3})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	setCandidateOnline(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({candidateOnline:true})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	setCandidateOffline(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({candidateOnline:false,candidateCanJoin:1})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	setTestCompleted(apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({testCompleted:true,candidateOnline:false,candidateCanJoin:1})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	sendMessageInVideoCall(msgObj:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("chat")
			.add(msgObj)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
	
	addRandomCandidateId(randomCandidateId:any,apptId:any) {
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("videocall")
			.doc(apptId.toString())
			.update({randomCandidateId:randomCandidateId})
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}

	addUpdateTestEntry(apptId:any,test:any) {
		let docData = {
			apptId: apptId.toString(),
			test : test
		};
		var promise = new Promise<any>((resolve, reject) => {
			this.firestore
			.collection("test")
			.doc(apptId.toString())
			.set(docData)
			.then(res => { resolve(true) }, err => reject(false));
		});
		return promise;
	}
}
