import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL;

	private mailCountChange = new Subject<any>();
	private mailOnlyCountChange = new Subject<any>();
	private notifiViewChange = new Subject<any>();
	private sessionExpMsg = new Subject<any>();
	
	constructor(private httpClient: HttpClient) { }

	sendCountChangeEvent() {
		this.mailCountChange.next();
	}
	getCountChangeEvent(): Observable<any> {
		return this.mailCountChange.asObservable();
	}

	sendOnlyCountChangeEvent() {
		this.mailOnlyCountChange.next();
	}
	getOnlyCountChangeEvent(): Observable<any> {
		return this.mailOnlyCountChange.asObservable();
	}
	
	public loginAPI(user:any){
		var data = {username:user.email,password:user.password}
		return this.httpClient.post(this.baseURL+"server/Users/signin",data);
	}

	public generate_qr(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/generate_qr",data,{responseType:"blob"});
	}

	public verifyGoogleAuthOTP(params:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/verify_otp",options);
	}

	public logout(){
		var token = localStorage.getItem('token');
		var data:any = {token:token!};
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"logout",data,{headers:headers});
	}
	
	public getAppoitmentsExcel(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/AppoinmentListExcel",data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public resetPassword(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/changePassword",data,{headers:headers});
	}

	public deleteAppt(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/delete_appointment",data,{headers:headers});
	}

	public getPsychologistList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_psychologist",{headers:headers});
	}

	public getDoctorList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_doctors",{headers:headers});
	}

	public getCompanyList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_company",{headers:headers});
	}

	public getBranchListByCompany(companyId:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listbranchByCompany",{company: companyId},{headers:headers});
	}

	public getRankCategoryList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_rank_category",{headers:headers});
	}

	public getRankList(){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_ranks",{headers:headers});
	}

	public getProfileList(){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/AllProfiles",{headers:headers});
	}

	public getApptTypeList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_appttype",{headers:headers});
	}

	public getNationalityList(){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_nationality",{headers:headers});
	}

	public getVesselListOld(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_vessel",{headers:headers});
	}

	public getVesselList(company:any=null){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(company){
			const params: any = { company: company};
			const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			return this.httpClient.get(this.baseURL+"server/Users/listVesselByCompany",options);
		}else{
			return this.httpClient.get(this.baseURL+"server/Users/list_vessel",{headers:headers});
		}
	}

	public createAppointment(data:any,uId:any = null){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(uId){
			return this.httpClient.put(this.baseURL+"server/Users/register_user/"+uId+"/",data,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/register_user",data,{headers:headers});
		}
	}

	public updateAppointment(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/UpdateApptData",data,{headers:headers});
	}

	public getAppointmentDetails(id:any){
		id = id.replace("%253D%253D","==");
		id = id.replace("%253d%253d","==");
		id = id.replace("%3D%3D","==");
		id = id.replace("%3d%3d","==");
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditApptData",options);
	}

	public getAppointmentDetailsRebook(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/ReApptData",options);
	}

	public getAgoraToken(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"api/generateTokenApi/",data,{headers:headers});
	}

	public getUserInfo(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/User_info",options);
	}

	public chatFileUpload(file:any,userId:any){
		const formData = new FormData();
		formData.append('file', file);
		formData.append('user', userId);
		let _headers = new HttpHeaders();
		return this.httpClient.post(this.baseURL+"server/Users/chatFileUpload", formData, { headers: _headers })
	}

	public deleteCompany(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_company",options);
	}

	public getCompanyListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listcompany",data,{headers:headers});
	}

	public createCompany(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		const formData = new FormData();
		formData.append('logo', data["data"]["logo"]);
		formData.append('comp_name', data["data"]["comp_name"]);
		formData.append('email', data["data"]["email"]);
		formData.append('phone_no', data["data"]["phone_no"]);
		formData.append('countryCode', data["data"]["countryCode"]);
		formData.append('address', data["data"]["address"]);
		formData.append('id', data["data"]["id"]);
		formData.append('password', data["data"]["password"]);
		formData.append('enable2fa', data["data"]["enable2fa"]);
		formData.append('is_send', data["data"]["is_send"]);
		formData.append('amount', data["data"]["amount"]);
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_company",formData,{headers:headers});
	}
	public getCompanyDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditCompanyData",options);
	}

	public sendEmailToDoctor(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/sendEmailToDoctor",data,{headers:headers});
	}

	public getFeedbackQuestions(){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json')
		return this.httpClient.get(this.baseURL+"server/Users/AllFeedbackQuestions",{headers:headers});
	}

	public getClinicListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listclinic",data,{headers:headers});
	}

	public deleteClinic(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_clinic",options);
	}

	public createClinic(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_clinic",data,{headers:headers});
	}
	public getClinicDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditClinicData",options);
	}

	public getDoctorListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listdoctor",data,{headers:headers});
	}

	public deleteDoctor(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_doctor",options);
	}

	public createDoctor(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		const formData = new FormData();
		if(data["data"]["stamp"]){
			formData.append('stamp', data["data"]["stamp"]);
		}
		formData.append('f_name', data["data"]["f_name"]);
		formData.append('email', data["data"]["email"]);
		formData.append('l_name', data["data"]["l_name"]);
		formData.append('role', data["data"]["role"]);
		formData.append('id', data["data"]["id"]);
		formData.append('password', data["data"]["password"]);
		formData.append('unique_id', data["data"]["unique_id"]);
		formData.append('qualification', data["data"]["qualification"]);
		formData.append('enable2fa', data["data"]["enable2fa"]);
		if(data["data"]["key_id"]){
			formData.append('key_id', data["data"]["key_id"]);
		}
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_doctor",formData,{headers:headers});
	}
	public getDoctorDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditDoctorData",options);
	}

	public getLanguageListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listlanguage",data,{headers:headers});
	}

	public deleteLanguage(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_language",options);
	}
	public getLanguageDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditLanguageData",options);
	}
	public createLanguage(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_language",data,{headers:headers});
	}

	public getScaleListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listscales",data,{headers:headers});
	}

	public deleteScale(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_scales",options);
	}

	public getScaleCategoryList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/getAllCategories",{headers:headers});
	}

	public getAllScaleList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/getAllScales",{headers:headers});
	}

	public getScaleDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditScaleData",options);
	}

	public createScale(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_scales",data,{headers:headers});
	}

	public getProfileListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listprofile",data,{headers:headers});
	}

	public deleteProfile(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_profile",options);
	}

	public createProfile(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_profile",data,{headers:headers});
	}

	public getProfileDetails(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditProfileData",options);
	}

	public getAllCompanyList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/list_company",{headers:headers});
	}

	public getBranchListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listbranch",data,{headers:headers});
	}

	public deleteBranch(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_branch",options);
	}

	public getBranchDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditBranchData",options);
	}

	public createBranch(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_branch",data,{headers:headers});
	}

	public getAllConsentFormQuestions(){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/AllConsentFormQuestions",{headers:headers});
	}

	public saveConsent(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/saveConsent",data,{headers:headers});
	}

	public getAllUsedLanguages(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/AllUsedLanguages",data,{headers:headers});
	}

	public getTranslation(data:any): Observable<any[]>{
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		let response1 = this.httpClient.post(this.baseURL+"server/Users/AllTranslatedQuestions",data,{headers:headers});
		let response2 = this.httpClient.post(this.baseURL+"server/Users/list_que_ans",{user_id:data["appId"]},{headers:headers});
		return forkJoin([response1, response2]);
	}

	public saveFeedback(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/saveFeedback",data,{headers:headers});
	}

	public saveTestTime(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/save_time",data,{headers:headers});
	}

	public getQuestionBankListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listquestion",data,{headers:headers});
	}

	public deleteQuestionBank(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_question",options);
	}

	public getAllProfileList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/AllProfiles",{headers:headers});
	}

	public getQuestionBankDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditQuestionData",options);
	}

	public createQuestionBank(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_question",data,{headers:headers});
	}

	public getAllLanguagesList(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/AllLanguages",{headers:headers});
	}

	public getQuestionTranslationListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/QuestionBank",data,{headers:headers});
	}

	public bulkCreateQuestionBank(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/BulkCreateQuestionBank",data,{headers:headers});
	}

	public answerExcelUpload(file:any,userId:any){
		const formData = new FormData();
		formData.append('file', file);
		formData.append('uid', userId);
		let _headers = new HttpHeaders();
		_headers = _headers.append("Authorization",localStorage.getItem('token')!);
		return this.httpClient.post(this.baseURL+"server/Users/AnswerExcelUpload", formData, { headers: _headers })
	}

	public find_v(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/find_v",options);
	}

	public scalex(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/scalex",options);
	}

	public final_ans(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/final_ans",options);
	}

	public scalex_std(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/scalex_std",options);
	}

	public getsummarydata(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/getsummarydata",options);
	}

	public addsummary(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/addsummary",data,{headers:headers});
	}

	public getMCMI3Report(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL+"server/Users/viewReportsNew",{params:new HttpParams(httpParams),headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public getMCMI3Answersheet(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL+"server/Users/ansReport",{params:new HttpParams(httpParams),headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public getMCMI4ReportPdf(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL+"server/Users/generateMCMI4ReportNew",{params:new HttpParams(httpParams),headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public getMCMI4Report(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getReport",data,{headers:headers});
	}

	public getUserConsent(data:any){
		/* var token = localStorage.getItem('token'); */
		let headers = new HttpHeaders();
		/* headers = headers.append("Authorization",token!); */
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getUserConsent",data,{headers:headers});
	}

	public GetScalesByProfileId(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/GetScalesByProfileId",data,{headers:headers});
	}

	public saveMCMI4Report(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/saveReport",data,{headers:headers});
	}

	public dataRequiredForMCMI4Dashboard(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/dataRequiredForMCMI4Dashboard/",{headers:headers});
	}

	public getPersonalityAndClinicalScales(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/getPersonalityAndClinicalScales/",{headers:headers});
	}

	public getChartData1(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData1/",data,{headers:headers});
	}

	public getChartData1_1(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData1_1/",data,{headers:headers});
	}

	public getChartData1_2(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData1_2/",data,{headers:headers});
	}

	public getChartData2(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData2/",data,{headers:headers});
	}

	public getChartData3(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData3/",data,{headers:headers});
	}

	public getChartData4(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData4/",data,{headers:headers});
	}

	public getChartData5(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData5/",data,{headers:headers});
	}

	public getChartData6(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData6/",data,{headers:headers});
	}

	public getChartData7(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData7/",data,{headers:headers});
	}

	public getChartData8(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData8/",data,{headers:headers});
	}

	public getChartData9(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData9/",data,{headers:headers});
	}

	public getChartData10(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getChartData10/",data,{headers:headers});
	}

	public dataRequiredForMCMI3Dashboard(){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/dataRequiredForDashboard/",{headers:headers});
	}

	public getCompanywisedata(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getCompanywisedata/",data,{headers:headers});
	}

	public getpiechartAgewisedata(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getpiechartAgewisedata/",data,{headers:headers});
	}

	public getpiechartdata(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getpiechartdata/",data,{headers:headers});
	}

	public getpiechartRankwisedata(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getpiechartRankwisedata/",data,{headers:headers});
	}

	public getStatisticsForChart(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/getStatisticsForChart/",data,{headers:headers});
	}

	public changeShareReport(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/shareUnshareReport",data,{headers:headers});
	}

	public checkUniqueCCID(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/checkUniqueCCID",data,{headers:headers});
	}

	public getRankCategoryListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listRankCategory",data,{headers:headers});
	}

	public deleteRankCategory(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_rankCategory",options);
	}

	public getRankCategoryDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditRankCategoryData",options);
	}

	public createRankCategory(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_rankCategory",data,{headers:headers});
	}

	public getReportTemplateListForTable(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listReportTemplate",data,{headers:headers});
	}

	public deleteReportTemplate(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = data;
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/delete_reportTemplate",options);
	}

	public getReportTemplateDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const params: any = { id: id};
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/EditReportTemplateData",options);
	}

	public createReportTemplate(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/register_reportTemplate",data,{headers:headers});
	}

	public reportTemplateListPagination(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listReportTemplatePagination",data,{headers:headers});
	}

	public getJson(){
		return this.httpClient.get('./assets/files/HC_Dashboard_Data.json');
	}

	public getFirebaseCredentials(){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"api/getFirebaseCredentials/",{headers:headers});
	}

	public saveUserAns(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/listUserAns",data,{headers:headers});
	}

	public saveSwatAns(data:any,userId:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/swatscore/"+userId+"/",data,{headers:headers});
	}

	public getUserAns(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/list_que_ans",data,{headers:headers});
	}

	public changePaymentStatus(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/save_payment_status",data,{headers:headers});
	}

	public changeApptStatus(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/save_appointmentStatus",data,{headers:headers});
	}

	public save_que(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/save_que",data,{headers:headers});
	}

	public sendVideoCallLink(apptId:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/VideoCallLink/"+apptId+"/",{},{headers:headers});
	}

	public sendPaymentLink(apptId:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/PaymentLink/"+apptId+"/",{},{headers:headers});
	}

	public getSwatQuestions(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/swat_quetion_ans",data,{headers:headers});
	}

	public alurt_que_get(apptId:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/alurt_que_get/"+apptId+"/",{headers:headers});
	}

	public time_validation_user(apptId:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/time_validation_user/"+apptId+"/",{headers:headers});
	}

	public translate_text_api(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/translate_text_api",data,{headers:headers});
	}

	public saveQuestionImage(formData:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/image_assign_que_api",formData,{headers:headers});
	}

	public notifyDoctor(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/sendPushNotificationToDoctor",data,{headers:headers});
	}

	public notifyDoctorOnSlack(id:any,data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/user_problem/"+id+"/",data,{headers:headers});
	}

	public countryCodes() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: headers });
	}

	public restoreRankCategory(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_rank_api/"+id+"/",{},{headers:headers});
	}

	public restoreProfile(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_profile_api/"+id+"/",{},{headers:headers});
	}

	public restoreClinic(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_clinic_api/"+id+"/",{},{headers:headers});
	}

	public restoreLanguage(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_language_api/"+id+"/",{},{headers:headers});
	}

	public getAppoitmentsPdf(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/AppoinmentReportPDF",data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public addVideoCallEntry(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/create_update_VideocallEntry",data,{headers:headers});
	}

	public getVideoCallEntry(id:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/get_VideocallEntry/"+id, { headers: headers });
	}

	public getVideoCallTranslationDetails(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/AllTranslation/"+id+"/",{headers: headers});
	}

	public deleteVideoCallTranslation(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.delete(this.baseURL+"server/Users/AllTranslation/"+id+"/");
	}

	public saveVideoCallTranslation(data:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			return this.httpClient.put(this.baseURL+"server/Users/AllTranslation/"+id+"/",data,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/AllTranslation",data,{headers:headers});
		}
	}

	public encrypt(id:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/encrypt/"+id+"/",{headers:headers});
	}

	public encryptMultiple(ids:any): Observable<any[]>{
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		let responses:any = [];
		for (let i = 0; i < ids.length; i++) {
			let id = ids[i];
			let response = this.httpClient.get(this.baseURL+"server/Users/encrypt/"+id+"/",{headers:headers});
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public decrypt(id:any){
		id = id.replace("%253D%253D","==");
		id = id.replace("%253d%253d","==");
		id = id.replace("%3D%3D","==");
		id = id.replace("%3d%3d","==");
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/decrypt/"+id+"/",{headers:headers});
	}

	public decryptMultiple(ids:any): Observable<any[]>{
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		let responses:any = [];
		for (let i = 0; i < ids.length; i++) {
			let id = ids[i];
			id = id.replace("%253D%253D","==");
			id = id.replace("%253d%253d","==");
			id = id.replace("%3D%3D","==");
			id = id.replace("%3d%3d","==");
			let response = this.httpClient.get(this.baseURL+"server/Users/decrypt/"+id+"/",{headers:headers});
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public restoreDoctor(id:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_Doctor_api/"+id+"/",{},{headers:headers});
	}

	public restoreCompany(id:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_company_api/"+id+"/",{},{headers:headers});
	}

	public reset2fa(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/reset2fa",data,{headers:headers});
	}

	public uploadReport(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		return this.httpClient.post(this.baseURL+"server/Users/UploadReport",data,{headers:headers});
	}

	public getMailDetails(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/send_report_email/"+id+"/",{flag:"get"},{headers:headers});
	}

	public send_report_email(id:any,data:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/send_report_email/"+id+"/",data,{headers:headers});
	}

	public getCurrency(params:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/CurrencyApi",options);
	}

	public deleteCurrency(params:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.delete(this.baseURL+"server/Users/CurrencyApi",options);
	}

	public createUpdateCurrency(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			return this.httpClient.put(this.baseURL+"server/Users/CurrencyApi",params,options);
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/CurrencyApi",params,{headers:headers});
		}
	}

	public restoreCurrency(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_currency/"+id+"/",{},{headers:headers});
	}

	public restoreReportText(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_report_text/"+id+"/",{},{headers:headers});
	}

	public getOrSaveReportExtras(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/add_grossmanfacet_responce",data,{headers:headers});
	}

	public getOrSaveReportExtras1(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/ReportText_responce",data,{headers:headers});
	}

	public getAllReportText(params:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/ReportTextApi",options);
		/* return this.httpClient.get(this.baseURL+"server/Users/ReportTextApi",{headers:headers}); */
	}

	public getReportText(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		/* const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/ReportTextApi",options); */
		return this.httpClient.get(this.baseURL+"server/Users/ReportTextApi/"+id+"/",{headers:headers});
	}

	public deleteReportText(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		/* const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.delete(this.baseURL+"server/Users/ReportTextApi",options); */
		return this.httpClient.delete(this.baseURL+"server/Users/ReportTextApi/"+id+"/",{headers:headers});
	}

	public createUpdateReportText(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			/* const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			return this.httpClient.put(this.baseURL+"server/Users/ReportTextApi",params,options); */
			return this.httpClient.put(this.baseURL+"server/Users/ReportTextApi/"+id+"/",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/ReportTextApi",params,{headers:headers});
		}
	}

	public deleteVessel(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.delete(this.baseURL+"server/Users/VesselMasterApi/"+id+"/",{headers:headers});
	}

	public getAllVessel(params:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/VesselMasterApi",options);
	}

	public getVessel(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/VesselMasterApi/"+id+"/",{headers:headers});
	}

	public createUpdateVessel(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			return this.httpClient.put(this.baseURL+"server/Users/VesselMasterApi/"+id+"/",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/VesselMasterApi",params,{headers:headers});
		}
	}

	public restoreVessel(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_vessel_api/"+id+"/",{},{headers:headers});
	}

	public getBillDetails(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/InvoiceApi",options);
	}

	public deleteBill(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.delete(this.baseURL+"server/Users/InvoiceApi",options);
	}

	public createUpdateBill(params:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(params.id){
			const httpParams: HttpParamsOptions = { fromObject: {id:params.id} } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			return this.httpClient.put(this.baseURL+"server/Users/InvoiceApi",params,options);
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/InvoiceApi",params,{headers:headers});
		}
	}

	public getBillPdfOld(data:any,url:string){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		/* headers = headers.append("Content-Type",'application/json'); */
		return this.httpClient.post(this.baseURL+"server/Users/"+url,data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public getBillPdf(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		/* headers = headers.append("Content-Type",'application/json'); */
		return this.httpClient.post(this.baseURL+"server/Users/invoice",data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public deleteWellnessAppt(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.delete(this.baseURL+"server/Users/WellnessCRUDApi/"+id+"/",{headers:headers});
	}

	public getWellnessAppt(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/WellnessCRUDApi/"+id+"/",{headers:headers});
	}

	public createUpdateWellnessAppt(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}else{
			headers = headers.append("Authorization","Bearer "+environment.FIX_TOKEN);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			return this.httpClient.put(this.baseURL+"server/Users/WellnessCRUDApi/"+id+"/",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/WellnessCRUDApi",params,{headers:headers});
		}
	}

	public restoreWellnessAppt(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_wellness/"+id+"/",{},{headers:headers});
	}

	public restoreScale(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_scale_api/"+id+"/",{},{headers:headers});
	}

	public restoreBranch(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_branch_api/"+id+"/",{},{headers:headers});
	}

	public getWellnessReportPdf(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL+"server/Users/generateWellnessReport",{params:new HttpParams(httpParams),headers:headers,observe: 'response', responseType: 'arraybuffer'});
	}

	public getWellnessReportPdfZip(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.post(this.baseURL+"server/Users/Api_to_get_zip",data,{headers:headers/* ,observe: 'response', responseType: 'arraybuffer' */});
	}

	/* public getWellnessReportPdfMultiple(ids:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		let responses:any = [];
		for (let i = 0; i < ids.length; i++) {
			const httpParams: HttpParamsOptions = { fromObject: {Id: ids[i], encrypt:"No"}} as HttpParamsOptions;
			let response = this.httpClient.get(this.baseURL+"server/Users/generateWellnessReport",{params:new HttpParams(httpParams),headers:headers,observe: 'response', responseType: 'arraybuffer'});
			responses.push(response);
		}
		return forkJoin(responses);
	} */

	public deleteWellnessQuestion(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.delete(this.baseURL+"server/Users/WellnessQuestionsCRUDApi/"+id+"/",{headers:headers});
	}

	public getWellnessQuestion(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/WellnessQuestionsCRUDApi/"+id+"/",{headers:headers});
	}

	public createUpdateWellnessQuestion(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			return this.httpClient.put(this.baseURL+"server/Users/WellnessQuestionsCRUDApi/"+id+"/",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/WellnessQuestionsCRUDApi",params,{headers:headers});
		}
	}

	public restoreWellnessQuestion(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_question/"+id+"/",{},{headers:headers});
	}

	public deleteWellnessScale(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.delete(this.baseURL+"server/Users/WellnessScaleCRUDApi/"+id+"/",{headers:headers});
	}

	public getWellnessScale(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/WellnessScaleCRUDApi/"+id+"/",{headers:headers});
	}

	public createUpdateWellnessScale(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			return this.httpClient.put(this.baseURL+"server/Users/WellnessScaleCRUDApi/"+id+"/",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/WellnessScaleCRUDApi",params,{headers:headers});
		}
	}

	public restoreWellnessScale(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_wellness_scale/"+id+"/",{},{headers:headers});
	}

	public assignDoctor(data:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/assignpsychologist",data,{headers:headers});
	}

	public getMailBoxCount() {
		let params = {
			draw:"1",
			start:"1",
			length:"1",
			searchVal:""
		}
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "server/Users/mailgun/MailboxApi", options);
	}

	public searchMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "server/Users/mailgun/MailboxApi", options);
	}

	public getEventMails(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "server/Users/mailgun/cand_linkedmails", options);
	}

	public moveMailListtoFolder(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/movemailto", data, { headers: headers });
	}

	public mailStatusSet(mId:any) {
		var data = {}
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/mark_read/"+mId+"/", data, { headers: headers });
	}

	public deleteMail(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/mailgun/delete_mail",data,{headers:headers});
	}

	public restoreMail(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/mailgun/restore_mail",data,{headers:headers});
	}

	public saveMail(data:any) {
		if(data.appt_id == undefined){
			data.appt_id = null;
		}
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/MailboxApi", data, { headers: headers });
	}

	public updateMail(data:any) {
		if(data.appt_id == undefined){
			data.appt_id = null;
		}
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.put(this.baseURL + "server/Users/mailgun/MailboxApi", data, { headers: headers });
	}

	public getWellnessPdfOrExcel(data:any,type:String){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		if(type=="pdf"){
			return this.httpClient.post(this.baseURL+"server/Users/generateWellnessTipIndexReport",data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/generateWellnessExcelVessel",data,{headers:headers,observe: 'response', responseType: 'arraybuffer'});
		}
	}

	public linkMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/link_mail", data, { headers: headers });
	}

	public unlinkMail(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/unlink_mail", data, { headers: headers });
	}

	public getMultipleMailDetails(ids:any): Observable<any[]>{
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		let responses:any = [];
		for (let i = 0; i < ids.length; i++) {
			const httpParams: HttpParamsOptions = { fromObject: {id:ids[i]} } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: headers };
			let response = this.httpClient.get(this.baseURL + "server/Users/mailgun/MailboxApi", options);
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public getMailHistory1(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "server/Users/mailgun/mail_history", options);
	}

	public getMailHistory(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/mail_history", data, { headers: headers });
	}

	public getMultipleApptDetails(data:any) {
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL + "server/Users/mailgun/user_details", data, { headers: headers });
	}

	public getInoviceNo(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.get(this.baseURL+"server/Users/getInoviceNo/"+id+"/",{headers:headers});
	}

	public deleteRank(id:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {id:id} } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.delete(this.baseURL+"server/Users/RankApi",options);
	}

	public getRankDetails(params:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL+"server/Users/RankApi",options);
	}

	public createUpdateRank(params:any,id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		if(id){
			params.id = id;
			return this.httpClient.put(this.baseURL+"server/Users/RankApi",params,{headers:headers});
		}else{
			return this.httpClient.post(this.baseURL+"server/Users/RankApi",params,{headers:headers});
		}
	}

	public restoreRank(id:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/restore_rank_api/"+id+"/",{},{headers:headers});
	}

	public verifyCallSign(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if(token){
			headers = headers.append("Authorization",token);
		}
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/verify_call_sign",data,{headers:headers});
	}

	public addTokenToCollectionForMailNotification(deviceId:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/Save_device_id",{device_id:deviceId},{headers:headers});
	}

	public saveScaleScore(data:any){
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token!);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/Save_std_score",data,{headers:headers});
	}

	public Api_to_save_user_call_status(data:any){
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/Api_to_save_user_call_status",data,{headers:headers});
	}

	public paid_unpaid(data:any){
		var token:any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization",token);
		headers = headers.append("Accept",'application/json');
		headers = headers.append("Accept",'application/json');
		return this.httpClient.post(this.baseURL+"server/Users/paid_unpaid",data,{headers:headers});
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}
