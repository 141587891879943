<div [ngStyle]="{'background-image':'url(../assets/img/mhs-login-page.jpg)','height':ScreenHeight+'px','background-repeat': 'round'}" class="mb-lg">
	<div *ngIf="loadershow" class="loader text-center">
		<img src="../assets/img/cc_loader.gif" />
	</div>
	<div class="container container-sm animated fadeInDown">
		<div style="background-color: #ffffff;margin-top: 60px;" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<div class="row">
				<div class="col-sm-12 text-center" style="margin-top: 30px;">
					<img src="../assets/img/MHS_DASHBOARD_LOGO.jpg" alt="Image" width="130px"/>
				</div>
			</div>
			<div class="row" *ngIf="step == 1">
				<div class="col-sm-12">
					<form role="form" (ngSubmit)="submitForm(user)">
						<div class="row mt20">
							<div class="col-sm-12">
								<div style="box-shadow: 2px 2px 2px #a69e9e;margin-bottom: 18px;" class="input-group m-b">
									<span class="input-group-addon labelColor1"><strong>Email-ID &nbsp;&nbsp;</strong></span>
									<input type="email" id="user_email" placeholder="Email-ID" class="form-control input_focus input-xs" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}" required/>
								</div>
							</div>
							<div class="col-sm-12">
								<div style="box-shadow: 2px 2px 2px #a69e9e;margin-bottom: 18px;" class="input-group m-b">
									<span class="input-group-addon labelColor1"><strong>Password &nbsp;</strong></span>
									<input type="password" id="user_password" placeholder="Password" class="form-control input_focus input-xs" [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" required />
									<span class="input-group-addon labelColor1" style="cursor: pointer;" (click)="togglePassword()">
										<i class="fa fa-eye" *ngIf="isPassword"></i>
										<i class="fa fa-eye-slash" *ngIf="!isPassword"></i>
									</span>
								</div>
							</div>
							<div class="col-sm-12 text-center" *ngIf="checkCapsLock">
								<div class="fs_15em text-warning text-bold"><br/><i class="fa fa-exclamation-triangle text-warning"></i> Caps Lock is on</div>
							</div>
							<div class="col-sm-12">
								<button id="btnSubmit" type="submit" class="btn labelColor1 btn-oval col-sm-12" style="width: 100%;"><strong>Log-In</strong></button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row" *ngIf="step == 2">
				<div class="col-sm-12" *ngIf="qrStep == 1">
					<div class="row mt20">
						<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<h3>Set Up Google Authenticator App</h3>
							<h4><div class="my-circle">1</div>Download an App</h4>
							<p style="padding-left: 30px;">
								Download the Google Authenticator App from&nbsp;
								<a href="https://play.google.com" target="_blank">Google Play Store</a>
								&nbsp;or&nbsp;
								<a href="https://apps.apple.com" target="_blank">App Store.</a>
							</p>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
							<div class="app-icon-container">
								<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank" style="margin-top: 30px;"><img src="../assets/img/get_it_on_google_play.png" style="width: 90%;height: auto;" /></a>
								<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img src="../assets/img/download_on_app_store.png" style="width: 90%;height: auto;margin-top: 10px;" /></a>
							</div>
							<button id="btnIHaveApp" type="button" class="btn labelColor1 btn-oval col-sm-12 mt-lg" style="width: 100%;margin-left: 10px;" (click)="next()"><strong>I Have An App</strong></button>
						</div>
					</div>
				</div>
				<div class="col-sm-12" *ngIf="qrStep == 2">
					<form role="form" (ngSubmit)="verifyOTP()">
						<div class="row mt20">
							<ng-container *ngIf="showQR">
								<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
									<h3>Set Up Google Authenticator App</h3>
									<h4><div class="my-circle">2</div>Add Your Account</h4>
									<p style="padding-left: 30px;">
										Scan the QR code to get the code you will need to complete the verification in step 3 and to verify future logins.
									</p>
									<h4><div class="my-circle">3</div>Verify Your Account</h4>
									<p style="padding-left: 30px;">
										Enter the 6 digit code provided from your authenticator app.
									</p>
								</div>
								<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
									<img src="{{qrToShow}}" alt="QR Code" style="width: 100%;height: auto;" />
								</div>
							</ng-container>
							<div class="col-sm-12">
								<div style="box-shadow: 2px 2px 2px #a69e9e;" class="input-group m-b">
									<span class="input-group-addon labelColor1"><strong>Code &nbsp;</strong></span>
									<input type="password" id="otp" placeholder="Enter Code" class="form-control input_focus input-xs" [(ngModel)]="auth.otp" [ngModelOptions]="{standalone: true}" required />
								</div>
							</div>
							<div class="col-sm-12">
								<button id="btnSubmit1" type="submit" class="btn labelColor1 btn-oval col-sm-12 mt-lg" style="width: 100%;"><strong>Verify</strong></button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-4 text-bold mt-lg"><a [routerLink]="['/terms/privacy']" target="_blank">Privacy Policy</a></div>
				<div class="col-sm-4 text-bold mt-lg text-center"><a [routerLink]="['/terms/service']" target="_blank">Terms of Service</a></div>
				<div class="col-sm-4 text-bold mt-lg"><a [routerLink]="['/terms/contactus']" target="_blank" class="pull-right">Contact us</a></div>
			</div>
			<div class="row mb-lg">
				<div class="col-sm-12 text-center text-black text-bold mt-sm">&nbsp;&copy; {{year}}&nbsp;<a href="http://3cubeservices.com/" target="_blank" class="text-black">3CUBE</a></div>
			</div>
			<div class="row" *ngIf="loginErrorshow">
				<div class="col-sm-12">
					<div class="alert alert-danger text-center" role="alert">
						<label>{{loginFormErr}}</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
