import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import {  FolderFilterPipe }   from './shared/pipes/folder-filter.pipe';
import {  MyFilterPipe }   from './shared/pipes/my-filter.pipe';
import {TruncatePipe} from './app.pipe';

import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import {  FilterUniqueNamePipe }   from './shared/pipes/unique-name.pipe';
import {  FilterUniqueQuestionPipe }   from './shared/pipes/unique-question.pipe';
//import { ReversePipe } from './reverse.pipe';
import {  ReversePipe }   from './shared/pipes/reverse-filter.pipe';
import { OrderByPipe } from './shared/pipes/order-by.pipe';
import {  SafeHtmlPipe }   from './shared/pipes/safe-html.pipe';
import { MailContentComponent } from './mail-content/mail-content.component';
import { VesselFormComponent } from './masters/vessel/vessel-form/vessel-form.component';
import { NgToggleModule } from 'ng-toggle-button';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    //SafeHtmlPipe,
	FolderFilterPipe,
	InfiniteScrollComponent,
	TruncatePipe,
	MyFilterPipe,
	FilterUniqueNamePipe,
	FilterUniqueQuestionPipe,
	ReversePipe,
	OrderByPipe,
	SafeHtmlPipe,
	MailContentComponent,
	VesselFormComponent
  ],
  imports: [
    CommonModule,
	NgToggleModule.forRoot(),
	ReactiveFormsModule,
		NgSelectModule,
		FormsModule,
  ],
  exports: [
	FolderFilterPipe,
	InfiniteScrollComponent,
	TruncatePipe,
	MyFilterPipe,
	FilterUniqueNamePipe,
	FilterUniqueQuestionPipe,
	ReversePipe,
	OrderByPipe,
	SafeHtmlPipe,
	MailContentComponent,
	VesselFormComponent,
	NgToggleModule,
	ReactiveFormsModule,
		NgSelectModule,
		FormsModule,
	]
})
export class SharedPipesModule { }
