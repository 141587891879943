import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from 'src/app/api.service';
import {ToastrService} from 'ngx-toastr';
declare var $: any;
import {emailPattern, mobilePattern} from '../../shared/constants';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-wellness-create',
	templateUrl: './wellness-create.component.html',
	styleUrls: ['./wellness-create.component.css']
})
export class WellnessCreateComponent implements OnInit {
	breadcrumbList:any[] = [];
	uId:any = null;
	encrypted_uId:any = null;
	pagetitle = "Create Wellness Appointment";
	msg = "Appointment created successfully.";
	moduleForm!: FormGroup;
	authForm!: FormGroup;
	showLoader = false;
	showLoader1 = false;
	customError:any = {};
	customError1:any = {};
	profile_list:any = [];
	rank_list:any = [];
	vessel_list:any = [];
	maxDate = new Date();
	step = 1;
	userAnsLoader = false;
	saveAnswerLoader = false;
	totalQueCount:any;
	QuizeList: any[] = [];
	hwfId = environment.hwfId;
	userType:any = localStorage.getItem("userType");
	OwnerId:any = localStorage.getItem("OwnerId");
	loggedInVessel:any = null;
	bmiColor = "";
	bmiCategory = "";
	nationality_list:any = [];
	logourl = "../../assets/img/cube_logo.jpeg";
	authorized = false;
	company_list:any = [];

	constructor(private toastr: ToastrService,private formBuilder: FormBuilder,private activeRoute: ActivatedRoute,private apiService: ApiService,private router: Router) { }
	
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			if(this.router.url.indexOf("create-wellness-appt")>=0){
				this.authorized = true;
				this.uId = null;
			}else if(this.router.url.indexOf("edit-wellness-appt")>=0){
				this.authorized = true;
				this.uId = routeParams["Id"];
			}else if(this.router.url.indexOf("appt-wellness")>=0){
				this.authorized = false;
				this.uId = null;
				this.loggedInVessel = parseInt(routeParams["Id"]);
				this.userType="candidate";
			}
			if(this.userType != "vessel" && this.userType != "wellness"){
				this.breadcrumbList = [
					{
						path: "/app/appointmentList",
						name: "Home"
					},
					{
						path: "/app/wellness",
						name: "Wellness"
					}
				];
			}if(this.userType == "vessel" || this.userType == "wellness"){
				this.breadcrumbList = [
					{
						path: "/app/wellness",
						name: "Wellness"
					}
				];
				if(this.userType == "vessel"){
					this.loggedInVessel = parseInt(this.OwnerId);
				}
			}else if(this.userType==null || this.userType==undefined){
				this.breadcrumbList = [];
			}
			var currentDateTime = new Date();
			currentDateTime.setHours(0,0,0,0);
			this.moduleForm = this.formBuilder.group({
				f_name:[null, [Validators.required]],
				l_name:[null, [Validators.required]],
				gender:[null, [Validators.required]],
				dob:[null],
				nationality:[null],
				email: [null, [Validators.pattern(emailPattern)]],
				age: [null],
				rank:[null, [Validators.required]],
				profile:[this.hwfId, [Validators.required]],
				company:[null],
				vessel:[this.loggedInVessel, [Validators.required]],
				weight:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				weight_pounds:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				height:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				height_feet:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				height_inch:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				BMI:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				BMR:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				DOE: [new Date(), [Validators.required]],
				time: [currentDateTime, [Validators.required]],
				/* Body_Fat:[null, [Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]], */
			});
			this.authForm = this.formBuilder.group({
				call_sign:[null, [Validators.required]]
			});
			this.f.BMI.disable();
			this.f.BMR.disable();
			/* this.f.Body_Fat.disable(); */
			this.f.age.disable();
			if(this.loggedInVessel){
				this.f.vessel.disable();
			}
			if(this.userType=="candidate"){
				this.f.nationality.setValidators(null);
			}else{
				this.f.nationality.setValidators([Validators.required]);
			}
			this.f.nationality.updateValueAndValidity();
			if(this.userType=="candidate" || this.userType=="vessel"){
				this.f.company.setValidators(null);
			}else{
				this.f.company.setValidators([Validators.required]);
			}
			this.f.company.updateValueAndValidity();
			this.getRankList();
			this.getProfileList();
			this.getCompanyList();
			this.getVesselList(null);
			this.getNationalityList();
			if(this.uId){
				this.pagetitle="Edit Wellness Appointment";
				this.msg = "Appointment updated successfully.";
				this.breadcrumbList.push({path: "/app/edit-wellness-appt/"+this.uId,name: "Edit Appointment"});
				this.getDetails();
				this.apiService.encrypt(this.uId).subscribe(data => {
					this.encrypted_uId = data;
				},(error) => {});
			}else{
				this.breadcrumbList.push({path: "/app/create-wellness-appt",name: "Create Appointment"});
			}
		});
	}

	heightFeetToCM(){
		try {
			let height_feet = this.f.height_feet.value;
			if(height_feet == null || height_feet == undefined || height_feet === ""){
				height_feet = 0;
			}
			let height_inch = this.f.height_inch.value;
			if(height_inch == null || height_inch == undefined || height_inch === ""){
				height_inch = 0;
			}
			let heightCM = this.round((parseFloat(!isNaN(height_feet)?height_feet:0) * 30.48) + (parseFloat(!isNaN(height_inch)?height_inch:0) * 2.54),2);
			this.f.height.setValue(heightCM);
		} catch (error) {}
		this.calculate_BMI_BMR_BodyWeight();
		
	}

	heightCMToFeet(){
		try {
			let height = this.f.height.value;
			if(height == null || height == undefined || height === ""){
				height = 0;
			}
			let totalInch = parseFloat(!isNaN(height)?height:0)/2.54;
			let feet = parseInt((totalInch/12).toString());
			let inch = totalInch - (feet*12);
			this.f.height_feet.setValue(feet);
			this.f.height_inch.setValue(this.round(inch,1));
		} catch (error) {}
		this.calculate_BMI_BMR_BodyWeight();
	}

	weightKGToPounds(){
		try {
			let weight = this.f.weight.value;
			if(weight == null || weight == undefined || weight === ""){
				weight = 0;
			}
			let weight_pounds = weight * 2.205;
			this.f.weight_pounds.setValue(this.round(weight_pounds,2));
		} catch (error) {}
		this.calculate_BMI_BMR_BodyWeight();
	}

	weightPoundsToKG(){
		try {
			let weight_pounds = this.f.weight_pounds.value;
			if(weight_pounds == null || weight_pounds == undefined || weight_pounds === ""){
				weight_pounds = 0;
			}
			let weight = weight_pounds / 2.205;
			this.f.weight.setValue(this.round(weight,2));
		} catch (error) {}
		this.calculate_BMI_BMR_BodyWeight();
	}

	getNationalityList() {
		this.apiService.getNationalityList().subscribe(data => {
			this.nationality_list = data;
		},(error) => {
			this.nationality_list = [];
		});
	}

	calculateAge(value: Date){
		if(value==null){
			this.f.age.setValue(null);
			return;
		}
		var ageDifMs = Date.now() - value.getTime();
		var ageDate = new Date(ageDifMs);
		this.f.age.setValue(Math.abs(ageDate.getUTCFullYear() - 1970));
	}

	calculate_BMI_BMR_BodyWeight(){
		let weight = this.f.weight.value;
		let height = this.f.height.value;
		let age = this.f.age.value;
		let gender = this.f.gender.value;

		let BMI:any = null;
		if(!this.isBlank(weight) && !this.isBlank(height)){
			BMI = this.round(((weight / height / height) * 10000),2)
		}
		if(isNaN(BMI) || BMI==Infinity){
			BMI = null;
		}
		this.f.BMI.setValue(BMI);
		this.setBMIColor();
		
		let BMR:any = null;
		if(!this.isBlank(age) && !this.isBlank(gender) && !this.isBlank(weight) && !this.isBlank(height)){
			BMR = this.round((10 * weight) + (6.25 * height) - (5 * age),2);
			if(gender == "Female"){
				BMR = BMR - 161;
			}else{
				BMR = BMR + 5;
			}
		}
		if(isNaN(BMR) || BMR==Infinity){
			BMR = null;
		}
		this.f.BMR.setValue(BMR);

		/* let Body_Fat:any = null;
		if(gender == "Female"){
			Body_Fat = this.round((weight * 0.732) + 8.987,2);
		}else{
			Body_Fat = this.round((weight * 1.082) + 94.42,2);
		}
		if(isNaN(Body_Fat) || Body_Fat==Infinity){
			Body_Fat = null;
		}
		this.f.Body_Fat.setValue(Body_Fat); */
	}

	setBMIColor(){
		let BMI = this.f.BMI.value;
		if(BMI == null){
			this.bmiColor = "";
			this.bmiCategory = "";
		}else if(BMI<18.5){
			this.bmiColor = "#ff0000";
			this.bmiCategory = "Underweight";
		}else if(BMI>=18.5 && BMI<=24.9){
			this.bmiColor = "#008000";
			this.bmiCategory = "Normal";
		}else if(BMI>24.9 && BMI<=29.9){
			this.bmiColor = "#ffa500";
			this.bmiCategory = "Over Weight";
		}else if(BMI>29.9){
			this.bmiColor = "#ff0000";
			this.bmiCategory = "Obese";
		}else{
			this.bmiColor = "";
			this.bmiCategory = "";
		}
	}

	round(value:number, precision:number) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	isBlank(val:any){
		if(val == null || val == undefined || val === ""){
			return true;
		}else{
			return false;
		}
	}

	getCompanyList() {
		this.apiService.getCompanyList().subscribe(data => {
			this.company_list = data;
		},(error) => {
			this.company_list = [];
		});
	}

	getVesselList(vesselValue:any) {
		this.f.vessel.setValue(vesselValue);
		this.apiService.getVesselList(this.f.company.value).subscribe(data => {
			this.vessel_list = data;
			this.vessel_list = this.vessel_list.slice(0);
		},(error) => {
			this.vessel_list = [];
		});
	}

	getRankList() {
		this.apiService.getRankList().subscribe(data => {
			this.rank_list = data;
		},(error) => {
			this.rank_list = [];
		});
	}

	getProfileList() {
		this.apiService.getProfileList().subscribe((data:any) => {
			this.profile_list = data.filter((item:any) => item.id == this.hwfId);
		},(error) => {
			this.profile_list = [];
		});
	}
	
	getDetails(){
		this.apiService.getWellnessAppt(this.uId).subscribe((data:any) => {
			this.f.f_name.setValue(data.f_name);
			this.f.l_name.setValue(data.l_name);
			this.f.gender.setValue(data.gender);
			this.f.nationality.setValue(data.nationality);
			this.f.email.setValue(data.email);
			var dob = data.dob;
			if(dob != null && dob !== ""){
				var dobArr = dob.split("-");
				/* var dd = dobArr[0];
				var mm = dobArr[1];
				dobArr[0] = mm;
				dobArr[1] = dd; */
				dob = new Date(dobArr.join("/"));
			}else{
				dob = null;
			}
			this.f.dob.setValue(dob);

			var DOE = data.DOE;
			var time = data.time;
			if(DOE != null && DOE !== ""){
				var doeArr = DOE.split("-");
				if(time != null){
					time = DOE+" "+time;
					time = new Date(time);
				}
				DOE = new Date(doeArr.join("/"));
			}else{
				DOE = null;
			}
			this.f.DOE.setValue(DOE);
			this.f.time.setValue(time);
			this.f.age.setValue(data.age);
			this.f.rank.setValue(data.rank);
			this.f.profile.setValue(data.profile);
			this.f.company.setValue(data.company);
			this.f.vessel.setValue(data.vessel);
			this.f.weight.setValue(data.weight);
			this.f.height.setValue(data.height);
			this.f.BMI.setValue(data.BMI);
			this.f.BMR.setValue(data.BMR);
			/* this.f.Body_Fat.setValue(data.Body_Fat); */
			this.heightCMToFeet();
			this.weightKGToPounds();
			this.setBMIColor();
		},(error) => {
			this.toastr.error("Unable to fetch details.", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}
	
	get f() { return this.moduleForm.controls; }

	get f1() { return this.authForm.controls; }

	clearCustomError(){
		Object.keys(this.f).forEach(key => {
			if(this.f[key].hasError('incorrect')){
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {}
			}
		});
		this.customError = {};
	}

	clearCustomError1(){
		Object.keys(this.f1).forEach(key => {
			if(this.f1[key].hasError('incorrect')){
				try {
					delete this.f1[key].errors?.incorrect;
					this.f1[key].updateValueAndValidity();
				} catch (error) {}
			}
		});
		this.customError1 = {};
	}

	back(){
		this.moduleForm.markAsPristine();
		this.step--;
	}
	
	saveData(){
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		if(this.moduleForm.pristine && this.uId){
			this.step++;
			this.getUserAnswers();
			return;
		}
		let payload:any = {
			"f_name":this.f.f_name.value,
			"l_name":this.f.l_name.value,
			"gender":this.f.gender.value,
			"nationality":this.f.nationality.value,
			"email":this.f.email.value,
			"dob":this.getDateStringFromDate(this.f.dob.value),
			"DOE":this.getDateStringFromDate(this.f.DOE.value),
			"time":this.getTimeStringFromDate(this.f.time.value),
			"age":this.f.age.value,
			"rank":this.f.rank.value,
			"profile":this.f.profile.value,
			"company":this.f.company.value,
			"vessel":this.f.vessel.value,
			"weight":this.f.weight.value,
			"height":this.f.height.value,
			"BMI":this.f.BMI.value,
			"BMR":this.f.BMR.value,
			/* "Body_Fat":this.f.Body_Fat.value, */
		}
		this.showLoader = true;
		this.apiService.createUpdateWellnessAppt(payload,this.uId).subscribe((data:any) => {
			this.showLoader = false;
			this.toastr.success(this.msg, 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			if(!this.uId){
				this.uId = data.id;
				this.apiService.encrypt(this.uId).subscribe(data => {
					this.encrypted_uId = data;
					this.step++;
					this.getUserAnswers();
				},(error) => {
					this.toastr.error("Something went wrong", 'Error',{
						timeOut:3000,
						tapToDismiss: true,
						closeButton: true
					});
					this.gotoList();
				});
			}else{
				this.step++;
				this.getUserAnswers();
			}
		},(error) => {
			this.showLoader = false;
			if(error.status==409){
				/* Swal.fire({
					title: 'Already Exists',
					html: 'Appointment for the candidate <strong>'+this.f.f_name.value+'</strong> already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor:'#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreWellnessAppt(error.error).subscribe((data:any) => {
							this.toastr.success("Appointment restored successfully", 'Success',{
								timeOut:3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						},(error) => {
							this.toastr.error("Something went wrong", 'Error',{
								timeOut:3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) {}
				}) */
			}else{
				let errorMsg = "Something went wrong.";
				if(error.status==400){
					errorMsg = error.error;
					if(typeof errorMsg=='object'){
						for (const [key, value] of Object.entries(errorMsg)) {
							let val:any = value;
							if(val[0]=="This field must be unique."){
								val[0] = "Already exists";
							}
							if(this.moduleForm.controls[key]){
								this.moduleForm.controls[key].setErrors({incorrect:true});
								this.customError[key] = val[0];
								let selector = "#"+key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) {}
								$(selector).focus();
							}else{
								this.toastr.error(key+":"+val[0], 'Error',{
									timeOut:3000,
									tapToDismiss: true,
									closeButton: true
								});
							}
						}
						return;
					}else{
						this.moduleForm.controls["f_name"].setErrors({incorrect:true});
						this.customError["f_name"] = "Record already exists";
						return;
					}
				}
				this.toastr.error(errorMsg, 'Error',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	getUserAnswers(){
		this.userAnsLoader = true;
		this.apiService.getTranslation({"language":null,"appId":this.encrypted_uId}).subscribe((data:any) => {
			this.userAnsLoader = false;
			let tempQuizeList = this.QuizeList;
			let newQuizeList = data[0];
			let savedQuizeList = data[1];
			for (let i = 0; i < newQuizeList.length; i++) {
				for (let k = 0; k < savedQuizeList.length; k++) {
					if(savedQuizeList[k].que_ids == newQuizeList[i].que_id){
						newQuizeList[i].answer = savedQuizeList[k].ans;
					}
				}
				for (let j = 0; j < tempQuizeList.length; j++) {
					if(newQuizeList[i].que_id == tempQuizeList[j].que_id){
						if(tempQuizeList[j].answer === "" || tempQuizeList[j].answer == null || tempQuizeList[j].answer == undefined){}else{
							newQuizeList[i].answer = tempQuizeList[j].answer;
						}
					}
				}
				/* if(this.userType != "vessel" && this.userType != "candidate"){
					if(newQuizeList[i].answer===''||newQuizeList[i].answer==null||newQuizeList[i].answer==undefined){
						newQuizeList[i].answer = 0;
					}
				} */
			}
			this.QuizeList = newQuizeList;
		},(error:any)=>{
			this.userAnsLoader = false;
			this.QuizeList = [];
		});
	}

	saveSingleAnswer(index:number){
		let payload = {
			user_id:this.uId,
			que_ids:this.QuizeList[index].que_id,
			ans:this.QuizeList[index].answer
		}
		this.apiService.save_que(payload).subscribe((data:any) => {},(error:any)=>{});
	}

	stopTimer() {
		let queAns:any = [];
		for (let i = 0; i < this.QuizeList.length; i++) {
			let ans:any = null;
			if(this.QuizeList[i].answer==="" || this.QuizeList[i].answer==null || this.QuizeList[i].answer==undefined){
				this.toastr.error("Please answer all questions.", 'Error',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				return;
			}else{
				ans = this.QuizeList[i].answer
			}
			let queAnsObj:any = {
				user_id:this.encrypted_uId,
				que_ids:this.QuizeList[i].que_id,
				ans:ans,
				void:"1"
			}
			queAns.push(queAnsObj);
		}
		let payload = {
			queAns:queAns,
			runScript:true
		}
		this.saveAnswerLoader = true;
		this.apiService.saveUserAns(payload).subscribe((data:any) => {
			this.saveAnswerLoader = false;
			this.toastr.success("Answers saved successfully.", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		},(error:any)=>{
			this.saveAnswerLoader = false;
			this.toastr.error("Something went wrong while saving answers.", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	getDateStringFromDate(date:any){
		if(date=="" || date==null || date==undefined){
			return null;
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var dayStr = day < 10 ? '0'+day : day;
		var monthStr = month < 10 ? '0'+month : month;
		var fromStr = year + "-" + monthStr + "-" + dayStr;
		return fromStr;
	}

	getTimeStringFromDate(dateObj:any){
		if(dateObj=="" || dateObj==null || dateObj==undefined){
			return "00:00:00";
		}
		var hour = dateObj.getHours() + "";
		var minutes = dateObj.getMinutes() + "";
		var seconds = dateObj.getSeconds() + "";
		hour = this.checkZero(hour);
		minutes = this.checkZero(minutes);
		seconds = this.checkZero(seconds);
		return hour + ":" + minutes + ":" + seconds;
	}

	checkZero(data:any){
		if(data != undefined && data.toString().length == 1){
			data = "0" + data;
		}
		return data;
	}
	
	gotoList(){
		if(this.userType=="candidate"){
			Swal.fire('Thank you','Your test is completed. You can close the tab now.','info');
			this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
				this.router.navigate(["/appt-wellness",this.loggedInVessel]);
			});
		}else{
			this.router.navigate(['/app/wellness-report',this.uId]);
		}
	}

	verifyCallSign(){
		this.clearCustomError1();
		if (this.authForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		for(let vessel of this.vessel_list){
			if(vessel.call_sign == this.f1.call_sign.value){
				this.authorized = true;
				this.toastr.success("Authorized successfully", 'Success',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				return;
			}
		}
		this.authorized = false;
		this.authForm.controls["call_sign"].setErrors({incorrect:true});
		this.customError1["call_sign"] = "Invalid call sign";
		this.toastr.error("Invalid call sign", 'Error',{
			timeOut:3000,
			tapToDismiss: true,
			closeButton: true
		});
	}
}