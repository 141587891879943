<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
	<div class="row mb-lg">
		<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
			<label><span style="color: #b40a09;">*&nbsp;</span>Name:</label>
			<input type="text" id="vessel" formControlName="vessel" placeholder="Name" class="form-control" />
			<small *ngIf="f.vessel.invalid && (modform.submitted || f.vessel.touched || f.vessel.dirty)" style="color: maroon;">
				<span *ngIf="f.vessel.errors?.required">
					Required
				</span>
				<span *ngIf="f.vessel.errors?.incorrect">
					{{customError.vessel}}
				</span>
			</small>
		</div>
		<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
			<label><span style="color: #b40a09;">*&nbsp;</span>Email Id:</label>
			<input type="email" id="email" name="email" formControlName="email" placeholder="Email Id" class="form-control" />
			<small *ngIf="f.email.invalid && (modform.submitted || f.email.touched || f.email.dirty)" style="color: maroon;">
				<span *ngIf="f.email.errors?.required">
					Required
				</span>
				<span *ngIf="f.email.errors?.pattern">
					Please enter valid email! Eg. demo&#64;mail.com
				</span>
				<span *ngIf="f.email.errors?.incorrect">
					{{customError.email}}
				</span>
			</small>
		</div>
		<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
			<label><span style="color: #b40a09;">*&nbsp;</span>Company:</label>
			<ng-select id="company" [items]="company_list" bindLabel="company" bindValue="id" [clearable]="false" placeholder="Select Company" name="company" formControlName="company">
			</ng-select>
			<small *ngIf="f.company.invalid && (modform.submitted || f.company.touched || f.company.dirty)" style="color: maroon;">
				<span *ngIf="f.company.errors?.required">
					Required
				</span>
				<span *ngIf="f.company.errors?.incorrect">
					{{customError.company}}
				</span>
			</small>
		</div>
	</div>
	<div class="row mb-lg">
		<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
			<label><span style="color: #b40a09;">*&nbsp;</span>Call Sign:</label>
			<input type="text" id="call_sign" formControlName="call_sign" placeholder="Call Sign" class="form-control" />
			<small *ngIf="f.call_sign.invalid && (modform.submitted || f.call_sign.touched || f.call_sign.dirty)" style="color: maroon;">
				<span *ngIf="f.call_sign.errors?.required">
					Required
				</span>
				<span *ngIf="f.call_sign.errors?.incorrect">
					{{customError.call_sign}}
				</span>
			</small>
		</div>
		<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
			<label>Two Factor Auth.:</label>
			<br />
			<ng-toggle formControlName="enable2fa" [color]="{ unchecked: '#bfcbd9', checked: '#2494f2' }" [fontColor]="{checked: '#ffffff',unchecked: '#000000'}" [switchColor]="{checked: '#4caf50',unchecked: '#f23022'}" [labels]="{'unchecked': 'Disable','checked': 'Enable'}" [width]="70"></ng-toggle>
		</div>
		<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="!uId">
			<label><span style="color: #b40a09;">*&nbsp;</span>Password:</label>
			<input type="password" id="password" name="password" formControlName="password" placeholder="Password" class="form-control" />
			<small *ngIf="f.password.invalid && (modform.submitted || f.password.touched || f.password.dirty)" style="color: maroon;">
				<p *ngIf="f.password.errors?.required">
					Required
				</p>
				<p [ngClass]="f.password.errors?.minlengtherror?'ErrorColor':'Suucesscolor'">
					Must be atleast 8 characters long.
				</p>
				<p [ngClass]="f.password.errors?.specialcharerror?'ErrorColor':'Suucesscolor'">
					Must have atleast 1 special character.
				</p>
				<p [ngClass]="f.password.errors?.uppercaseerror?'ErrorColor':'Suucesscolor'">
					Must have atleast 1 upper case character.
				</p>
				<p [ngClass]="f.password.errors?.lowercaseerror?'ErrorColor':'Suucesscolor'">
					Must have atleast 1 lower case character.
				</p>
			</small>
		</div>
	</div>
	<div class="row mb-lg">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
			<button type="submit" [disabled]="showLoader" id="btnSubmit" ripple="" class="mr mb-sm btn btn-sm btn-success">
				<span class="mr-sm">
					<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i>
					<i *ngIf="!showLoader" aria-hidden="true" class="fa fa-save"></i>
				</span>
				<span>Save</span>
			</button>
			<button type="button" id="btnCancel" class="mr mb-sm btn btn-sm btn-default" (click)="clearForm();">Cancel</button>
		</div>
	</div>
</form>