import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output} from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { emailPattern, password_validation } from 'src/app/shared/constants';

@Component({
	selector: 'vessel-form',
	templateUrl: './vessel-form.component.html',
	styleUrls: ['./vessel-form.component.css']
})
export class VesselFormComponent implements OnInit {
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	pageLoading: boolean = false;
	customError:any = {};
	company_list:any = [];
	@Input() uId: any = null;
	@Input() name: any = null;
	@Input() companyId: any = null;
	@Output() created = new EventEmitter<any>();
	@Output() cleared = new EventEmitter<any>();
	
	constructor(private toastr: ToastrService,private apiService: ApiService,private formBuilder: FormBuilder,private router: Router,private activeRoute: ActivatedRoute) {
		
	}
	
	ngOnInit(): void {
		this.moduleForm = this.formBuilder.group({
			vessel: [null, [Validators.required]],
			company: [null, [Validators.required]],
			call_sign: [null, [Validators.required]],
			email: [null, [Validators.required,Validators.pattern(emailPattern)]],
			enable2fa:[false],
			password: ['', [Validators.required,password_validation]],
		});
		this.getCompanyList();
		if(this.name){
			this.f.vessel.setValue(this.name);
			this.moduleForm.markAsDirty();
		}
		if(this.companyId){
			this.f.company.setValue(this.companyId);
			this.moduleForm.markAsDirty();
		}
		if(this.uId){
			this.f.password.clearValidators();
			this.f.password.setValidators(null);
			this.f.password.updateValueAndValidity();
			this.getDetails();
		}
	}
	
	get f() { return this.moduleForm.controls; }
	
	clearForm(){
		this.moduleForm.reset();
		this.cleared.emit();
	}
	
	getDetails(){
		this.pageLoading = true;
		this.apiService.getVessel(this.uId).subscribe((data:any) => {
			console.log(data)
			this.pageLoading = false;
			let res:any = data;
			this.f.vessel.setValue(res.vessel);
			this.f.email.setValue(res.email);
			this.f.company.setValue(res.company);
			this.f.enable2fa.setValue(res.enable2fa);
			this.f.call_sign.setValue(res.call_sign);
		},(error) => {
			this.pageLoading = false;
			if(error.status==401){return}
			this.toastr.error("Unable to fetch details.", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}
	
	clearCustomError(){
		Object.keys(this.f).forEach(key => {
			if(this.f[key].hasError('incorrect')){
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {
					
				}
				
			}
		});
		this.customError = {};
	}

	getCompanyList() {
		this.apiService.getCompanyList().subscribe(data => {
			this.company_list = data;
		},(error) => {
			this.company_list = [];
		});
	}
	
	saveData(){
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let data:any = {
			vessel:this.f.vessel.value,
			email:this.f.email.value,
			company:this.f.company.value,
			enable2fa:this.f.enable2fa.value,
			call_sign:this.f.call_sign.value,
			password:this.f.password.value
		};
		/* if(this.uId){
			data.id = this.uId;
		} */
		this.showLoader = true;
		this.apiService.createUpdateVessel(data,this.uId).subscribe((data:any) => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.created.emit(data.id);
			this.clearForm();
		},(error:any) => {
			this.showLoader = false;
			if(error.status==401){return}
			if(error.status==409){
				Swal.fire({
					title: 'Already Exists',
					html: 'Vessel with name <strong>'+this.f.vessel.value+'</strong> already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor:'#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreVessel(error.error).subscribe((data:any) => {
							this.toastr.success("Vessel restored successfully", 'Success',{
								timeOut:3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.created.emit(error.error);
							this.clearForm();
						},(error) => {
							this.toastr.error("Something went wrong", 'Error',{
								timeOut:3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) {}
				})
			}else{
				let errorMsg = "Something went wrong.";
				if(error.status==400){
					errorMsg = error.error;
					if(typeof errorMsg=='object'){
						for (const [key, value] of Object.entries(errorMsg)) {
							let val:any = value;
							if(val[0]=="This field must be unique."){
								val[0] = "Already exists";
							}
							if(this.moduleForm.controls[key]){
								this.moduleForm.controls[key].setErrors({incorrect:true});
								this.customError[key] = val[0];
								let selector = "#"+key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) {}
								$(selector).focus();
							}else{
								this.toastr.error(key+":"+val[0], 'Error',{
									timeOut:3000,
									tapToDismiss: true,
									closeButton: true
								});
							}
						}
						return;
					}else{
						this.moduleForm.controls["email"].setErrors({incorrect:true});
						this.customError["email"] = "Email-Id already exists";
						return;
					}
				}
				this.toastr.error(errorMsg, 'Error',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}
}