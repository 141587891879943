import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatedHttpService implements HttpInterceptor{
	constructor(private router: Router,private toastr: ToastrService) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe( tap(() => {},(err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status !== 401) {
					/* let url = request.url;
					console.log(url) */
					/* this.toastr.error("Test alert message.", 'Error',{
						tapToDismiss: true,
						closeButton: true,
						timeOut:3000
					}); */
					return;
				}
				localStorage.clear();
				localStorage.setItem("sessionexpire","true");
				this.router.navigate(['/login']);
			}
		}));
	}
}
