import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RefreshComponent } from './refresh/refresh.component';
import { HttpClientModule } from '@angular/common/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UrlSerializerService } from './url-serializer.service';
import {UrlSegment, UrlSerializer} from '@angular/router';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './firebase/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from './authenticated-http.service';
import {  SafePipe }   from './shared/pipes/safe.pipe';
import { SharedPipesModule } from './shared-pipes.module';
import { DataTablesModule } from 'angular-datatables';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';





@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		RefreshComponent,
		RecoverComponent,
		ResetPasswordComponent,
		SafePipe,
  
	],
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		NgbModule,
		RichTextEditorAllModule,
		NgxFileDropModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		SharedPipesModule,
		DataTablesModule,
		DateInputsModule
	],
	providers: [
		{
			provide: UrlSerializer,
			useClass: UrlSerializerService
		},
		MessagingService,
		AsyncPipe,
		{
			provide: HTTP_INTERCEPTORS, 
			useClass: AuthenticatedHttpService, 
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);