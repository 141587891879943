<ng-container *ngIf="userType=='candidate'">
	<header class="my-header">
		<nav role="navigation" class="navbar topnavbar">
			<div class="navbar-header collapse navbar-collapse">
				<a class="navbar-brand mr0 ml0 pr0 pl20" style="padding-top: 8px;padding-bottom: 8px;pointer-events: none;">
					<img src="{{logourl}}" />
				</a>
			</div>
		</nav>
	</header>
	<div style="margin-bottom: 80px;height: 1px;"></div>
</ng-container>
<ol *ngIf="userType!='candidate'" class="breadcrumb">
	<li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index" [class.active]="i===breadcrumbList.length-1">
		<a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
			<b>{{ item.name }}</b>
		</a>
		<span *ngIf="i===breadcrumbList.length-1"><b>{{ item.name }}</b></span>
	</li>
</ol>
<div *ngIf="userAnsLoader" class="loader text-center">
	<img src="../assets/img/loading.gif" style="width: 100px;" />
	<p style="color: black;font-weight: bolder;">Loading</p>
</div>
<div class="panel panel-info" *ngIf="authorized">
	<div class="panel-body">
		<ul id="progressbar" style="text-align: center;">
			<li [ngClass]="step>=1 ? 'active ' : 'inactive'" style="color: black;">
				<h3 *ngIf="userType=='vessel' || userType=='candidate'">Crew Details</h3>
				<h3 *ngIf="userType!='vessel' && userType!='candidate'">Candidate Details</h3>
			</li>
			<li [ngClass]="step>=2 ? 'active' : 'inactive'" style="color: black;">
				<h3 *ngIf="userType=='vessel' || userType=='candidate'">Survey Questions</h3>
				<h3 *ngIf="userType!='vessel' && userType!='candidate'">Questionnaire</h3>
			</li>
		</ul>
		<form class="msform" [formGroup]="moduleForm" (ngSubmit)="saveData()" #compform="ngForm">
			<fieldset  *ngIf="step == 1" >
				<h2 class="fs-title">Step 1: Enter Candidate Details</h2>
				<div class="row mb-lg">
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>First Name:</label>
						<input type="text" id="f_name" formControlName="f_name" placeholder="First Name" class="form-control" />
						<small *ngIf="f.f_name.invalid && (compform.submitted || f.f_name.touched || f.f_name.dirty)" style="color: maroon;">
							<span *ngIf="f.f_name.errors?.required">
								Required
							</span>
							<span *ngIf="f.f_name.errors?.incorrect">
								{{customError.f_name}}
							</span>
						</small>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Last Name:</label>
						<input type="text" id="l_name" formControlName="l_name" placeholder="Last Name" class="form-control" />
						<small *ngIf="f.l_name.invalid && (compform.submitted || f.l_name.touched || f.l_name.dirty)" style="color: maroon;">
							<span *ngIf="f.l_name.errors?.required">
								Required
							</span>
							<span *ngIf="f.l_name.errors?.incorrect">
								{{customError.l_name}}
							</span>
						</small>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Gender :</label>
						<div>
							<div class="radio-inline c-radio">
								<label>
									<input id="maleId" formControlName="gender" type="radio" name="gender" value="Male" (change)="calculate_BMI_BMR_BodyWeight()" />
									<span class="fa fa-male"></span>
									Male
								</label>
							</div>
							<div class="radio-inline c-radio">
								<label>
									<input id="femaleId" formControlName="gender" type="radio" name="gender" value="Female" (change)="calculate_BMI_BMR_BodyWeight()" />
									<span class="fa fa-female"></span>
									Female
								</label>
							</div>
						</div>
						<small *ngIf="f.gender.invalid && (compform.submitted || f.gender.touched || f.gender.dirty)" style="color: maroon;">
							<span *ngIf="f.gender.errors?.required">
								Required
							</span>
							<span *ngIf="f.gender.errors?.incorrect">
								{{customError.gender}}
							</span>
						</small>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Date Of Birth:</label>
						<kendo-datepicker format="dd-MM-yyyy" style="width: 100%;" formControlName="dob" [placeholder]="'dd-MM-yyyy'" (valueChange)="calculateAge($event);calculate_BMI_BMR_BodyWeight();" [max]="maxDate"></kendo-datepicker>
						<small *ngIf="f.dob.invalid && (compform.submitted || f.dob.touched || f.dob.dirty)" style="color: maroon;">
							<span *ngIf="f.dob.errors?.maxError">
								Invalid date
							</span>
							<span *ngIf="f.dob.errors?.incorrect">
								{{customError.dob}}
							</span>
						</small>
					</div>
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Age(Auto generated):</label>
						<input type="text" id="age" name="age" formControlName="age" placeholder="Age" class="form-control" />
						<small *ngIf="f.age.invalid && (compform.submitted || f.age.touched || f.age.dirty)" style="color: maroon;">
							<span *ngIf="f.age.errors?.incorrect">
								{{customError.age}}
							</span>
						</small>
					</div>
				</div>
				<div class="row mb-lg">
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Weight (kg):</label>
						<br />
						<div style="width: 90%;float: left;">
							<input type="text" id="weight" formControlName="weight" class="form-control" (keyup)="weightKGToPounds();" />
							<small *ngIf="f.weight.invalid && (compform.submitted || f.weight.touched || f.weight.dirty)" style="color: maroon;">
								<span *ngIf="f.weight.errors?.pattern">
									Invalid value
								</span>
								<span *ngIf="f.weight.errors?.incorrect">
									{{customError.weight}}
								</span>
							</small>
						</div>
						<div style="width: 10%;float: left;"><label class="mt-sm">&nbsp;kg</label></div>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Weight (lbs):</label>
						<br />
						<div style="width: 90%;float: left;">
							<input type="text" id="weight_pounds" formControlName="weight_pounds" class="form-control" (keyup)="weightPoundsToKG();" />
							<small *ngIf="f.weight_pounds.invalid && (compform.submitted || f.weight_pounds.touched || f.weight_pounds.dirty)" style="color: maroon;">
								<span *ngIf="f.weight_pounds.errors?.pattern">
									Invalid value
								</span>
								<span *ngIf="f.weight_pounds.errors?.incorrect">
									{{customError.weight_pounds}}
								</span>
							</small>
						</div>
						<div style="width: 10%;float: left;"><label class="mt-sm">&nbsp;lbs</label></div>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Height (cm):</label>
						<br />
						<div style="width: 90%;float: left;">
							<input type="text" id="height" formControlName="height" class="form-control" (keyup)="heightCMToFeet();" />
							<small *ngIf="f.height.invalid && (compform.submitted || f.height.touched || f.height.dirty)" style="color: maroon;">
								<span *ngIf="f.height.errors?.pattern">
									Invalid value
								</span>
								<span *ngIf="f.height.errors?.incorrect">
									{{customError.height}}
								</span>
							</small>
						</div>
						<div style="width: 10%;float: left;"><label class="mt-sm">&nbsp;cm</label></div>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Height (ft in):</label>
						<br />
						<div style="width: 40%;float: left;">
							<input type="text" id="height_feet" formControlName="height_feet" class="form-control" (keyup)="heightFeetToCM();" />
							<small *ngIf="f.height_feet.invalid && (compform.submitted || f.height_feet.touched || f.height_feet.dirty)" style="color: maroon;">
								<span *ngIf="f.height_feet.errors?.pattern">
									Invalid value
								</span>
								<span *ngIf="f.height_feet.errors?.incorrect">
									{{customError.height_feet}}
								</span>
							</small>
						</div>
						<div style="width: 10%;float: left;"><label class="mt-sm">&nbsp;ft</label></div>
						<div style="width: 40%;float: left;">
							<input type="text" id="height_inch" formControlName="height_inch" class="form-control" (keyup)="heightFeetToCM();" />
							<small *ngIf="f.height_inch.invalid && (compform.submitted || f.height_inch.touched || f.height_inch.dirty)" style="color: maroon;">
								<span *ngIf="f.height_inch.errors?.pattern">
									Invalid value
								</span>
								<span *ngIf="f.height_inch.errors?.incorrect">
									{{customError.height_inch}}
								</span>
							</small>
						</div>
						<div style="width: 10%;float: left;"><label class="mt-sm">&nbsp;in</label></div>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>BMI:<label *ngIf="bmiCategory" [ngStyle]="{'color':bmiColor}">({{bmiCategory}})</label></label>
						<input type="text" id="BMI" formControlName="BMI" placeholder="BMI" class="form-control" [ngStyle]="{'border-color':(bmiColor ? bmiColor : ''),'border-width':(bmiColor ? 'medium' : '')}" />
						<small *ngIf="f.BMI.invalid && (compform.submitted || f.BMI.touched || f.BMI.dirty)" style="color: maroon;">
							<span *ngIf="f.BMI.errors?.pattern">
								Invalid value
							</span>
							<span *ngIf="f.BMI.errors?.incorrect">
								{{customError.BMI}}
							</span>
						</small>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>BMR:</label>
						<input type="text" id="BMR" formControlName="BMR" placeholder="BMR" class="form-control" />
						<small *ngIf="f.BMR.invalid && (compform.submitted || f.BMR.touched || f.BMR.dirty)" style="color: maroon;">
							<span *ngIf="f.BMR.errors?.pattern">
								Invalid value
							</span>
							<span *ngIf="f.BMR.errors?.incorrect">
								{{customError.BMR}}
							</span>
						</small>
					</div>
				</div>
				<div class="row mb-lg">
					<div *ngIf="userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Nationality:</label>
						<ng-select id="nationality" appendTo="body" [items]="nationality_list" bindLabel="name" bindValue="id" [clearable]="false" placeholder="Select Nationality" formControlName="nationality">
						</ng-select>
						<small *ngIf="f.nationality.invalid && (compform.submitted || f.nationality.touched || f.nationality.dirty)" style="color: maroon;">
							<span *ngIf="f.nationality.errors?.required">
								Please select nationality.
							</span>
							<span *ngIf="f.nationality.errors?.incorrect">
								{{customError.nationality}}
							</span>
						</small>
					</div>
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Date Of Evaluation:</label>
						<kendo-datepicker style="width: 100%;" format="dd-MM-yyyy" formControlName="DOE" [placeholder]="'dd-MM-yyyy'"></kendo-datepicker>
						<small *ngIf="f.DOE.invalid && (compform.submitted || f.DOE.touched || f.DOE.dirty)" style="color: maroon;">
							<span *ngIf="f.DOE.errors?.required">
								Please enter DOE.
							</span>
							<span *ngIf="f.DOE.errors?.incorrect">
								{{customError.DOE}}
							</span>
						</small>
					</div>
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Time:</label>
						<kendo-timepicker style="width: 100%;" format="HH:mm:ss" formControlName="time" [placeholder]="'HH:mm:ss'"></kendo-timepicker>
						<small *ngIf="f.time.invalid && (compform.submitted || f.time.touched || f.time.dirty)" style="color: maroon;">
							<span *ngIf="f.time.errors?.required">
								Please enter time.
							</span>
							<span *ngIf="f.time.errors?.incorrect">
								{{customError.time}}
							</span>
						</small>
					</div>
					<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Rank:</label>
						<ng-select id="rank" appendTo="body" [items]="rank_list" bindLabel="rank" bindValue="id" [clearable]="true" placeholder="Select Rank" name="rank" formControlName="rank">
						</ng-select>
						<small *ngIf="f.rank.invalid && (compform.submitted || f.rank.touched || f.rank.dirty)" style="color: maroon;">
							<span *ngIf="f.rank.errors?.required">
								Required.
							</span>
							<span *ngIf="f.rank.errors?.incorrect">
								{{customError.rank}}
							</span>
						</small>
					</div>
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Profile:</label>
						<ng-select id="profile" appendTo="body" [items]="profile_list" bindLabel="name" bindValue="id" [clearable]="false" placeholder="Select Profile" name="profile" formControlName="profile">
							<ng-template ng-label-tmp let-item="item">
								{{item.name}} ({{item.age?'Below 18':'Above 18'}})
							</ng-template>
							<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
								{{item.name}} ({{item.age?'Below 18':'Above 18'}})
							</ng-template>
						</ng-select>
						<small *ngIf="f.profile.invalid && (compform.submitted || f.profile.touched || f.profile.dirty)" style="color: maroon;">
							<span *ngIf="f.profile.errors?.required">
								Required.
							</span>
							<span *ngIf="f.profile.errors?.incorrect">
								{{customError.profile}}
							</span>
						</small>
					</div>
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Company:</label>
						<ng-select id="company" appendTo="body" [items]="company_list" bindLabel="company" bindValue="id" [clearable]="false" placeholder="Select Company" name="company" formControlName="company" (change)="getVesselList(null);">
						</ng-select>
						<small *ngIf="f.company.invalid && (compform.submitted || f.company.touched || f.company.dirty)" style="color: maroon;">
							<span *ngIf="f.company.errors?.required">
								Please select company.
							</span>
							<span *ngIf="f.company.errors?.incorrect">
								{{customError.company}}
							</span>
						</small>
					</div>
					<div *ngIf="userType=='vessel' || userType=='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label>Email Id:</label>
						<input type="text" id="email" name="email" formControlName="email" placeholder="Email Id" class="form-control" />
						<small *ngIf="f.email.invalid && (compform.submitted || f.email.touched || f.email.dirty)" style="color: maroon;">
							<span *ngIf="f.email.errors?.pattern">
								Please enter valid email! Eg. demo&#64;mail.com
							</span>
							<span *ngIf="f.email.errors?.incorrect">
								{{customError.email}}
							</span>
						</small>
					</div>
					<!-- <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
						<label>Body Fat:</label>
						<input type="text" id="Body_Fat" formControlName="Body_Fat" placeholder="Body Fat" class="form-control" />
						<small *ngIf="f.Body_Fat.invalid && (compform.submitted || f.Body_Fat.touched || f.Body_Fat.dirty)" style="color: maroon;">
							<span *ngIf="f.Body_Fat.errors?.pattern">
								Invalid value
							</span>
							<span *ngIf="f.Body_Fat.errors?.incorrect">
								{{customError.Body_Fat}}
							</span>
						</small>
					</div> -->
					<div *ngIf="userType!='vessel' && userType!='candidate'" class="col-lg-2 col-md-2 col-sm-12 col-xs-12 cust-col">
						<label><span style="color: #b40a09;">*&nbsp;</span>Vessel:</label>
						<ng-select id="vessel" appendTo="body" [items]="vessel_list" bindLabel="vessel" bindValue="id" [clearable]="true" placeholder="Select Vessel" formControlName="vessel">
						</ng-select>
						<small *ngIf="f.vessel.invalid && (compform.submitted || f.vessel.touched || f.vessel.dirty)" style="color: maroon;">
							<span *ngIf="f.vessel.errors?.required">
								Required.
							</span>
							<span *ngIf="f.vessel.errors?.incorrect">
								{{customError.vessel}}
							</span>
						</small>
					</div>
				</div>
				<div class="row mb-lg">
					<div class="col-md-12 text-center">
						<button type="submit" [disabled]="showLoader" id="btnSubmit" ripple="" class="mr-sm btn btn-sm btn-success">
							<span class="mr-sm">
								<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i>
								<i *ngIf="!showLoader" aria-hidden="true" class="fa fa-arrow-right"></i>
							</span>
							<span>Next</span>
						</button>
						<button type="button" id="btnCancel" class="btn btn-sm btn-default" [routerLink]="['/app/wellness']">Cancel</button>
					</div>
				</div>
			</fieldset>
		</form>
		<form class="msform">
			<fieldset *ngIf="step == 2" >
				<h2 class="fs-title">Step 2: Submit Answers</h2>
				<div class="row mb-lg">
					<div class="col-md-12">
						<div class="table-responsive">
							<table class="table table-bordered table-hover">
								<thead>
									<tr>
										<th>
											<div class="row">
												<div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">#</div>
												<div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">Question</div>
												<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">Answer</div>
											</div>
										</th>
									</tr>
								</thead>
								<tbody *ngIf="QuizeList?.length">
									<ng-container *ngFor="let que of QuizeList;let i = index;">
										<tr>
											<td>
												<div class="row">
													<div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">{{i+1}}</div>
													<div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
														<div [style.color]="que.answer===''||que.answer==null||que.answer==undefined ? '#d60606' : '#46a149'" [innerHTML]="que.question_name | safeHtml"></div>
													</div>
													<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
														<div class="radio-inline c-radio col-sm-12">
															<label class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
																<input id="ans1_{{i}}" [(ngModel)]="que.answer" type="radio" name="answer{{i}}" [value]="0" (change)="saveSingleAnswer(i)" />
																<span class="fa fa-check"></span>
																NOT AT ALL
															</label>
															<label class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
																<input id="ans1_{{i}}" [(ngModel)]="que.answer" type="radio" name="answer{{i}}" [value]="1" (change)="saveSingleAnswer(i)" />
																<span class="fa fa-check"></span>
																SOME DAYS
															</label>
															<label class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
																<input id="ans1_{{i}}" [(ngModel)]="que.answer" type="radio" name="answer{{i}}" [value]="2" (change)="saveSingleAnswer(i)" />
																<span class="fa fa-check"></span>
																MOST DAYS
															</label>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</ng-container>
								</tbody>
								<tbody *ngIf="!QuizeList?.length">
									<ng-container>
										<tr>
											<td style="text-align: center;"><strong>Answers not yet submitted</strong></td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row mb-lg">
					<div class="col-md-12 text-center">
						<button class="mr-sm btn btn-sm btn-success" [disabled]="saveAnswerLoader" (click)="stopTimer()">
							<i *ngIf="!saveAnswerLoader" class="fa fa-save mr-sm"></i>
							<i *ngIf="saveAnswerLoader" class="fa fa-spinner fa-spin mr-sm"></i>
							Submit
						</button>
						<button type="button" id="btnCancel" class="btn btn-sm btn-default" (click)="back()">
							<span class="mr-sm">
								<i aria-hidden="true" class="fa fa-arrow-left"></i>
							</span>
							<span>Back</span>
						</button>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
</div>
<div class="row">
	<div *ngIf="!authorized" class="col-lg-4 col-lg-offset-4 col-md-4 col-md-offset-4 col-sm-12 col-xs-12">
		<div class="panel panel-info">
			<div class="panel-body">
				<h2 class="fs-title">Enter Call Sign for authorization</h2>
				<form class="msform" [formGroup]="authForm" (ngSubmit)="verifyCallSign()" #authform="ngForm">
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cust-col">
							<label><span style="color: #b40a09;">*&nbsp;</span>Call Sign:</label>
							<input type="text" id="call_sign" formControlName="call_sign" placeholder="Call Sign" class="form-control" />
							<small *ngIf="f1.call_sign.invalid && (authform.submitted || f1.call_sign.touched || f1.call_sign.dirty)" style="color: maroon;">
								<span *ngIf="f1.call_sign.errors?.required">
									Required
								</span>
								<span *ngIf="f1.call_sign.errors?.incorrect">
									{{customError1.call_sign}}
								</span>
							</small>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-md-12 text-center">
							<button type="submit" [disabled]="showLoader1" id="btnSubmitAuth" ripple="" class="mr-sm btn btn-sm btn-success">
								<span class="mr-sm">
									<i *ngIf="showLoader1" aria-hidden="true" class="fa fa-spinner fa-spin"></i>
								</span>
								<span>Submit</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
